import * as React from "react";

import Head from "next/head";
import { useFormatMessage } from "utils/locales";

export function NextPageHead() {
  const formatMessage = useFormatMessage();

  return (
    <>
      <Head>
        <title>{formatMessage("package_search__header_title")} | KeyOpsTech</title>s
      </Head>
    </>
  );
}
