import { useIntl } from "react-intl";
import { useLang } from "hoofd";
import type { PrimitiveType } from "intl-messageformat";

import * as sourceOfTruth from "@generated/locales/en.json";
import { useEffect } from "react";

export type LocaleMessages = typeof sourceOfTruth;
export type LocaleKey = keyof LocaleMessages;

export const SupportedLocales = {
  en: "English",
  fr: "Français",
  pt: "Português",
  wo: "Wolof",
};
export type SupportedLocalesType = keyof typeof SupportedLocales;

export const STORED_LOCALE_KEY = "preferredLocale";

export function isSupportedLocale(tested: string) {
  return Object.keys(SupportedLocales).some((locale) => locale === tested);
}

export function useFormatMessage(): (
  id: LocaleKey, // only accepts valid keys, not any string
  values?: Record<string, PrimitiveType>
) => string {
  const intl = useIntl();

  return (id, values) => intl.formatMessage({ id }, values);
}

export function handleSelectPreferedLocale(locale: SupportedLocalesType) {
  localStorage.setItem(STORED_LOCALE_KEY, locale);

  window.location.reload();
}

// return type on this signature enforces that all languages have the same translations defined
export function importMessages(
  locale: SupportedLocalesType
): Promise<LocaleMessages & { default: LocaleMessages }> {
  switch (locale) {
    case "en":
      return import("@generated/locales/en.json");
    case "fr":
      return import("@generated/locales/fr.json");
    case "pt":
      return import("@generated/locales/pt.json");
    case "wo":
      return import("@generated/locales/wo.json");
  }
}

export function formatCountryNames(
  locale: SupportedLocalesType | string,
  countryCode: string
) {
  let countryName: string;

  if (!countryCode || typeof window === "undefined") {
    return countryCode;
  }

  const countriesFromLocale = new (Intl as any).DisplayNames([locale], {
    type: "region",
  });

  try {
    countryName = countriesFromLocale.of(countryCode);
  } catch (error) {
    console.error(
      `Parsing country code: Cannot parse this country code ${error} - ${countryCode}`
    );
    countryName = countryCode;
  }

  return countryName;
}

export function useLocaleChange(selectedLocale: SupportedLocalesType | null = "en") {
  useLang(selectedLocale!);

  useEffect(() => {
    if (selectedLocale) {
      (window as any).__LOCALE__ = selectedLocale;
    }
  }, [selectedLocale]);
}
