import { shouldPolyfill as shouldPolyfillNumberFormat } from "@formatjs/intl-numberformat/should-polyfill";

/**
 * Dynamically polyfill Intl API & its locale data
 * @param locale locale to polyfill
 */
export async function polyfill(locale: string = "") {
  let dataPolyfills = [] as any[];

  // Polyfill Intl.NumberFormat if necessary
  if (shouldPolyfillNumberFormat()) {
    await import(
      /* webpackChunkName: "polyfill-intl-numberformat" */ "@formatjs/intl-numberformat/polyfill"
    );
  }

  if ((Intl.NumberFormat as any).polyfilled) {
    switch (locale) {
      default:
        dataPolyfills.push(
          import(
            // @ts-ignore
            /* webpackChunkName: "intl-numberformat-en" */ "@formatjs/intl-numberformat/locale-data/en"
          )
        );
        break;
      case "fr":
        dataPolyfills.push(
          import(
            // @ts-ignore
            /* webpackChunkName: "intl-numberformat-fr" */ "@formatjs/intl-numberformat/locale-data/fr"
          )
        );
        break;
      case "pt":
        dataPolyfills.push(
          import(
            // @ts-ignore
            /* webpackChunkName: "intl-numberformat-fr" */ "@formatjs/intl-numberformat/locale-data/pt"
          )
        );
        break;
    }
  }

  await Promise.all(dataPolyfills);
}
