import * as React from "react";
import { IntlProvider, ReactIntlErrorCode } from "react-intl";

import App, { AppProps } from "next/app";
import { useRouter } from "next/router";
//@ts-ignore
import NProgress from "nprogress";

import * as gtag from "utils/gtag";
import { initSentry } from "utils/senty";
import {
  importMessages,
  SupportedLocalesType,
  LocaleMessages,
  STORED_LOCALE_KEY,
  SupportedLocales,
  useLocaleChange,
} from "utils/locales";

import { NextPageHead } from "components/NextPageHead";

import { NextErrorPageProps } from "./_error";

import { polyfill } from "../../polyfills";

import "utils/styles/tailwind.css";
import "utils/styles/tailwind-components.css";
import "utils/styles/tailwind-utils.css";
import "@reach/dialog/styles.css";

initSentry();

type MyAppProps = AppProps &
  NextErrorPageProps & { locale: SupportedLocalesType; messages: any };

function MyApp({ Component, pageProps, locale, messages, err }: MyAppProps) {
  const router = useRouter();

  const [
    clientSideLocale,
    setClientSideLocale,
  ] = React.useState<SupportedLocalesType | null>(null);
  const [
    clientSideMessages,
    setClientSideMessage,
  ] = React.useState<LocaleMessages | null>(null);

  React.useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url);
      NProgress.done();
    };

    if (router) {
      router.events.on("routeChangeComplete", handleRouteChange);
      router.events.on("routeChangeStart", () => {
        console.log(`Loading: ${router.pathname}`);
        NProgress.start();
      });
    }
    return () => {
      router?.events.off("routeChangeStart", () => NProgress.start());
      router?.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, router?.events]);

  React.useEffect(() => {
    const preferredStoredLocale = localStorage.getItem(
      STORED_LOCALE_KEY
    ) as typeof clientSideLocale;

    setClientSideLocale(preferredStoredLocale);

    if (preferredStoredLocale) {
      importMessages(preferredStoredLocale).then((messages) =>
        setClientSideMessage(messages.default)
      );
    }
  }, []);

  const selectedLocale = clientSideLocale || locale;
  const selectedMessages = clientSideMessages || messages;

  useLocaleChange(selectedLocale);

  return (
    <>
      <IntlProvider
        locale={selectedLocale}
        messages={selectedMessages!}
        onError={(err) => {
          if (
            // @ts-ignore
            err.code === ReactIntlErrorCode.MISSING_DATA &&
            err.message.includes("wo")
          ) {
            return;
          }
          console.error(err);
        }}
      >
        <NextPageHead />
        <Component {...pageProps} {...err} />
      </IntlProvider>
    </>
  );
}

export const getInitialProps: typeof App.getInitialProps = async (appContext) => {
  const {
    ctx: { req, res },
  } = appContext;

  const request = req!;
  const response = res!;
  const req_host = request.headers?.host!;
  const req_sub_domain = req_host?.split(".")[0];

  if (req_sub_domain === "t") {
    response.writeHead(301, { location: `https://tracking.kotscan.com${request.url}` });
    response.end();
  }

  const navigatorLang = typeof navigator !== "undefined" && navigator.language;
  const parsedNavigatorLanguage =
    navigatorLang && navigatorLang?.includes("fr") ? "fr" : "en";

  const serverLocale = (req as any)?.locale?.includes("-")
    ? (req as any)?.locale.split("-")[0]
    : (req as any)?.locale;

  const windowLocale = typeof window !== "undefined" && (window as any).LOCALE;

  const requestedLocale: SupportedLocalesType =
    (Array.isArray(serverLocale) ? serverLocale[0] : serverLocale) ||
    parsedNavigatorLanguage ||
    (req as any)?.lang ||
    (windowLocale?.includes(",") ? windowLocale.split(",")[0] : windowLocale) ||
    "en";

  const locale = requestedLocale in SupportedLocales ? requestedLocale : "en";

  const importedMessages = importMessages(locale);

  const [selectedMessages, appProps] = await Promise.all([
    importedMessages,
    polyfill(locale),
    App.getInitialProps(appContext),
  ]);

  return {
    ...(appProps as any),
    messages: selectedMessages?.default,
    locale,
  };
};

MyApp.getInitialProps = getInitialProps;

export default MyApp;
